<script>
import * as d3 from 'd3';
import d3Tip from 'd3-tip';

export default {
  name: 'MyChart',
  props: {
    chartData: {
      type: Array,
      default() {
        return [];
      },
    },
    stroke: {
      type: String,
      default: '#ccc',
    },
  },
  data() {
    return {
      svgWidth: null,
      svgHeight: null,
      x: null,
      y: null,
      svg: null,
      line: null,
      area: null,
      xAxis: null,
      margin: {
        top: 20,
        right: 30,
        bottom: 30,
        left: 40,
      },
      tip: null,
    };
  },
  watch: {
    chartData() {
      this.yMax = 0;
      this.chartData.forEach((el) => {
        if (this.yMax < Number(el[1])) this.yMax = Number(el[1]);
      });
      this.updateChart();
    },
  },
  async mounted() {
    this.$nextTick(() => {
      const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
      this.svgWidth = this.$el.offsetWidth;
      let ratio = 0.6;
      // alert(window.innerWidth);
      if (window.innerWidth <= 600) ratio = 1;
      this.svgHeight = this.$el.parentElement.offsetWidth * ratio - fontSize * 17;
      this.svg = d3.select('#chart')
        .attr('viewBox', [0, 0, this.svgWidth, this.svgHeight]);
      this.createX();
      this.createY();
      this.line = d3.line()
        .x((d) => this.x(d.date))
        .y((d) => this.y(d.value));
      this.area = d3.area()
        .x((d) => this.x(d.date))
        .y0(this.y(0))
        .y1((d) => this.y(d.value));
      this.xAxis = (g) => g
        .attr('transform', `translate(0, ${this.svgHeight - this.margin.bottom + 10})`)
        .attr('class', 'x axis')
        .call(
          d3.axisBottom(this.x)
            .ticks(this.svgWidth / 60)
            .tickFormat(d3.timeFormat('%b %d'))
            .tickSizeOuter(0)
            .tickSizeInner(0),
        )
        .call((gs) => gs.select('.domain').remove());
      this.tip = d3Tip()
        .attr('class', 'd3-tip')
        .offset([-10, 0])
        .html((d) => `<strong>Frequency:</strong> <span style='color:red'>${d.frequency}</span>`);
      this.svg.call(this.tip);
      this.svg.append('g')
        .call(this.xAxis);
      this.svg.append('linearGradient')
        .attr('id', 'area-gradient')
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', 0)
        .attr('y1', this.y(0))
        .attr('x2', 0)
        .attr('y2', 0)
        .selectAll('stop')
        .data([
          { offset: '0%', color: 'rgba(248, 252, 253, 0.26)' },
          { offset: '100%', color: '#d4d4f9' },
        ])
        .enter()
        .append('stop')
        .attr('offset', (d) => d.offset)
        .attr('stop-color', (d) => d.color);
      // append area
      this.svg.append('path')
        .datum(this.chartData)
        .attr('stroke', 'transparent')
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr('class', 'area')
        .attr('d', this.area);
      // append line
      this.svg.append('path')
        .attr('stroke-width', 3)
        .attr('stroke', this.stroke)
        .attr('fill', 'none')
        .attr('class', 'line')
        .datum(this.chartData)
        .attr('d', this.line);
      // mouse actions
      const mouseG = this.svg.append('g')
        .attr('class', 'mouse-over-effects');
      // append mouse line
      mouseG.append('path')
        .attr('class', 'mouse-line')
        .style('stroke-dasharray', '3,3')
        .style('stroke', 'black')
        .style('stroke-width', '1px')
        .style('opacity', '0');
      // append g on each data
      const mousePerLine = mouseG.selectAll('.mouse-per-line')
        .data([this.chartData])
        .enter()
        .append('g')
        .attr('class', 'mouse-per-line');
      // append circle (mouse pointer)
      mousePerLine.append('circle')
        .attr('r', 5)
        .style('stroke', '#283285')
        .style('fill', '#fff')
        .style('stroke-width', '1px')
        .style('opacity', '0');
      // append text
      const label = mousePerLine
        .append('text')
        .style('font-weight', 'bold')
        .attr('transform', 'translate(15,3)');
      const textBg = mousePerLine.insert('rect', 'text')
        .attr('class', 'text-bg')
        .style('fill', '#fff');
      // append a rect to catch mouse movements on canvas
      mouseG.append('svg:rect')
        .attr('transform', `translate(${this.margin.left}, ${this.margin.bottom})`)
        .attr('width', this.svgWidth - this.margin.left - this.margin.right)
        .attr('height', this.svgHeight - this.margin.top - this.margin.bottom)
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
        // on mouse out hide line, circles and text
        .on('mouseout', () => {
          d3.select('.mouse-line')
            .style('opacity', '0');
          d3.selectAll('.mouse-per-line circle')
            .style('opacity', '0');
          label.style('opacity', '0');
          textBg.style('opacity', '0');
          this.$emit('setCurrentPrice', this.chartData[this.chartData.length - 1].value);
        })
        // on mouse in show line and circles
        .on('mouseover', () => {
          d3.select('.mouse-line')
            .style('opacity', '1');
          d3.selectAll('.mouse-per-line circle')
            .style('opacity', '1');
          label.style('opacity', '1');
          d3.select('.text-bg')
            .style('opacity', '1');
        })
        // mouse moving over canvas
        .on('mousemove', (e) => {
          d3.select('.text-bg')
            .attr('x', d3.selectAll('.mouse-per-line text').node().getBBox().x + 10)
            .attr('y', d3.selectAll('.mouse-per-line text').node().getBBox().y)
            .attr('rx', 5)
            .attr('width', d3.selectAll('.mouse-per-line text').node().getBBox().width + 10)
            .attr('height', d3.selectAll('.mouse-per-line text').node().getBBox().height + 5);
          const mouse = d3.pointer(e);
          let xPos = 0;
          // first move circle and store x position
          d3.select('.mouse-per-line')
            .attr('transform', () => {
              const x0 = this.x.invert(mouse[0] + this.margin.left);
              const bisect = d3.bisector((d) => d.date).right;
              const i = bisect(this.chartData, x0, 1);
              const d0 = this.chartData[i - 1];
              const d1 = this.chartData[i];
              const d = x0 - d0.date > d1.date - x0 ? d1 : d0;
              this.$emit('setCurrentPrice', d.value.toFixed(4));
              xPos = this.x(d.date);
              d3.select('.mouse-per-line').select('text').text(d.value.toFixed(6));
              return `translate(${xPos},${this.y(d.value)})`;
            });
          // move line
          d3.select('.mouse-line')
            .attr('d', () => `M${xPos}, ${this.svgHeight - this.margin.bottom} ${xPos}, 0`);
        });
    });
  },
  methods: {
    createX() {
      this.x = d3.scaleUtc()
        .domain(d3.extent(this.chartData, (d) => d.date))
        .range([this.margin.left, this.svgWidth - this.margin.right]);
    },
    createY() {
      this.y = d3.scaleLinear()
        .domain([0, d3.max(this.chartData, (d) => d.value)])
        .range([this.svgHeight - this.margin.bottom, this.margin.top]);
    },
    updateChart() {
      // this.svg.transition();
      this.createX();
      this.createY();
      this.svg.select('.area')
        .datum(this.chartData)// change the area
        .attr('d', this.area);
      this.svg.select('.line')
        .datum(this.chartData)// change the line
        .attr('d', this.line);
      this.svg.select('.x.axis') // change the x axis
        .call(this.xAxis);
    },
  },
};
</script>

<template>
  <div class='chart' ref='chart'>
    <svg id='chart'></svg>
  </div>
</template>

<style lang="scss">
#chart {
  overflow: visible;
}
.area {
  fill: url(#area-gradient);
  stroke-width: 0;
}
.x.axis {
  font-weight: 600;
  font-size: 1em;
  @media screen and (max-width: $breakpoint-md) {
    font-size: 2em;
  }
  @media screen and (max-width: $breakpoint-sm) {
    font-size: 1em;
  }
}
.mouse-per-line text {
  @media screen and (max-width: $breakpoint-sm) {
    font-size: 3em;
  }
}
</style>
