<script>
import { mapState } from 'vuex';
import BlenderMaker from '@/components/Blender/BlenderMaker';
import BlenderStatistic from '@/components/Blender/BlenderStatistic/BlenderStatistic';
import Button from '@/components/Button';

export default {
  name: 'Blender',
  components: {
    BlenderStatistic,
    BlenderMaker,
    Button,
  },
  computed: {
    ...mapState({
      chainId: (state) => state.User.chainId,
    }),
  },
};
</script>

<template>
  <v-col class="main-wrap main-wrap--narrow" v-if="chainId === 56 || chainId === 97">
    <h1 class="page-title">{{ $t('eth-only') }}</h1>
    <v-row justify="center">
      <Button to="/">
        {{ $t('not-found.button') }}
      </Button>
    </v-row>
  </v-col>
  <v-col class="main-wrap" v-else>
    <div class="page-content">
      <h1 class="page-title">{{ $t('blender.title') }}</h1>
      <v-row justify="center">
        <v-col class="col-md-6 col-12">
          <BlenderMaker/>
        </v-col>
        <v-col class="col-md-6 col-12" v-if="chainId !== 4">
          <BlenderStatistic/>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<style scoped lang="scss"></style>
