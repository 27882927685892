<script>
import Button from '@/components/Button';
import ApproveButton from '@/components/ApproveButton';

import milk0 from '@/assets/img/blender/milk-bottle-0.svg';
import milk1 from '@/assets/img/blender/milk-bottle-1.svg';
import milk2 from '@/assets/img/blender/milk-bottle-2.svg';
import milk3 from '@/assets/img/blender/milk-bottle-3.svg';
import milkMax from '@/assets/img/blender/milk-bottle-max.svg';

import shake0 from '@/assets/img/blender/shake-glass-0.svg';
import shake1 from '@/assets/img/blender/shake-glass-1.svg';
import shake2 from '@/assets/img/blender/shake-glass-2.svg';
import shake3 from '@/assets/img/blender/shake-glass-3.svg';
import shakeMax from '@/assets/img/blender/shake-glass-max.svg';
import { mapState } from 'vuex';

const milks = [
  milk0,
  milk1,
  milk2,
  milk3,
  milkMax,
];
const shakes = [
  shake0,
  shake1,
  shake2,
  shake3,
  shakeMax,
];

export default {
  name: 'BlenderMaker',
  components: { ApproveButton, Button },
  computed: {
    ...mapState({
      milkBalance: (state) => state.Milk.milkBalance,
      shakeBalance: (state) => state.Shake.balance,
      shakePrice: (state) => state.Blender.shakePrice,
      milkAllowance: (state) => state.Blender.milkAllowance,
    }),
    shakeCupValue() {
      const shake = this.$BN(this.shakeBalance);
      if (shake.comparedTo(10) > 0) return 4;
      if (shake.comparedTo(7) > 0) return 3;
      if (shake.comparedTo(4) > 0) return 2;
      if (shake.comparedTo(0) > 0) return 1;
      return 0;
    },
    milkBottleValue() {
      const milk = this.$BN(this.milkBalance);
      const shake = this.$BN(this.shakePrice);
      if (milk.comparedTo(shake) > 0) return 4;
      if (milk.comparedTo(shake.times(0.7)) > 0) return 3;
      if (milk.comparedTo(shake.times(0.4)) > 0) return 2;
      if (milk.comparedTo(shake.times(0.1)) > 0) return 1;
      return 0;
    },
    shakeImg() {
      return shakes[this.shakeCupValue];
    },
    milkImg() {
      return milks[this.milkBottleValue];
    },
  },
  methods: {
    getShake() {
      this.$store.dispatch('Blender/getShake');
    },
    approveMilk() {
      const amount = this.$BN(10).pow(18).times(this.$BN(10000000000));
      this.$store.dispatch('Blender/approveMilk', { amount: amount.toString(10) });
    },
    approveShake() {
      const amount = this.$BN(10).pow(18).times(this.$BN(10000));
      this.$store.dispatch('Blender/approveShake', { amount: amount.toString(10) });
    },
  },
};
</script>

<template>
  <div class="blender main-block">
    <div class="blender-item">
      <img :src="milkImg" class="blender-item__logo"/>
      <span class="blender-item__name">{{ $t('blender.milk-balance') }}</span>
      <span class="blender-item__value">
        {{ milkBalance | amount(3) }} {{ $t('blender.milk') }}
      </span>
      <ApproveButton
        v-if="!(milkAllowance >= 0)"
        name="milk2"
        approveName="blender"
      />
      <Button
        v-else
        :disabled="!milkBalance || !shakePrice || milkBalance.comparedTo(shakePrice) < 0"
        @click="getShake"
      >
        {{ $t('blender.make-shake') }}
      </Button>
    </div>
    <img
      :src="require(`@/assets/img/arrow-right.svg`)"
      alt="arrows"
      class="blender__arrows"/>
    <div class="blender-item">
      <img :src="shakeImg" class="blender-item__logo blender-item__logo--shake"/>
      <span class="blender-item__name">{{ $t('blender.shake-balance') }}</span>
      <span class="blender-item__value">
        {{ shakeBalance | amount(3) }} {{ $t('blender.shake') }}
      </span>
      <Button
        href="https://app.uniswap.org/#/swap?inputCurrency=0x6006fc2a849fedaba8330ce36f5133de01f96189&outputCurrency=ETH"
        target="_blank"
        class="btn--shake"
      >
        {{ $t('blender.trade') }}
      </Button>
    </div>
  </div>
</template>

<style lang="scss">
.blender {
  display: flex;
  align-items: flex-start;
  font-size: 1rem;
  padding: 13em 3.8em 5.6em;
  @media screen and (max-width: $breakpoint-md) {
    font-size: 2rem;
  }
  @media screen and (max-width: $breakpoint-sm) {
    font-size: 1rem;
    position: relative;
    padding: 5em 2em 24em;
  }

  &__arrows {
    flex-shrink: 0;
    width: 5.6em;
    margin: 10em 1em 0;
    @media screen and (max-width: $breakpoint-sm) {
      margin: 6em 1.7em 0;
      width: 7rem;
    }
  }

  &-item {
    flex: 1 1 0;
    text-align: center;

    &__logo {
      display: block;
      margin: 0 auto 7em 0;
      height: 25em;
      @media screen and (max-width: $breakpoint-sm) {
        height: 14em;
      }

      &--shake {
        margin-left: auto;
      }
    }

    &__name,
    &__value {
      display: block;
      text-transform: uppercase;
      font-weight: 600;
    }

    &__name {
      font-size: 1.2em;
      margin-bottom: 1em;
      @media screen and (max-width: $breakpoint-sm) {
        font-size: 1.6em;
      }
    }

    &__value {
      font-size: 2.3em;
      margin-bottom: 2em;
    }

    .btn {
      width: 100%;
      @media screen and (max-width: $breakpoint-sm) {
        position: absolute;
        bottom: calc(5% + 5em);
        left: 5%;
        width: 90%;
        &--shake {
          bottom: 5%;
        }
      }
    }
  }
}
</style>
