<script>
import BlenderStatisticSummary from '@/components/Blender/BlenderStatistic/BlenderStatisticSummary';
import BlenderStatisticChart from '@/components/Blender/BlenderStatistic/BlenderStatisticChart';

export default {
  name: 'BlenderStatistic',
  components: { BlenderStatisticChart, BlenderStatisticSummary },
};
</script>

<template>
  <div class="blender-statistic">
    <BlenderStatisticChart/>
    <BlenderStatisticSummary/>
  </div>
</template>

<style lang="scss">
.blender-statistic {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
