import axios from 'axios';

const getPairChartData = (pairAddress, skip) => axios.post('https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2', {
  query: `{
            pairDayDatas(first: 1000, skip: ${skip}, orderBy: date, orderDirection: asc, where: { pairAddress: "${pairAddress}" }) {
              id
              date
              reserve0
              reserve1
              dailyVolumeToken0
              dailyVolumeToken1
              dailyVolumeUSD
              reserveUSD
            }
          }`,
});

const getTokenChartData = (tokenAddress, skip) => axios.post('https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2', {
  query: `{
            tokenDayDatas(first: 1000, skip: ${skip}, orderBy: date, orderDirection: asc, where: { token: "${tokenAddress}" }) {
              id
              date
              priceUSD
              totalLiquidityToken
              totalLiquidityUSD
              totalLiquidityETH
              dailyVolumeETH
              dailyVolumeToken
              dailyVolumeUSD
            }
          }`,
});

const uniGraph = {
  getPairChartData,
  getTokenChartData,
};

export default uniGraph;
