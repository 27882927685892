<script>
import { mapState } from 'vuex';
import { getShakeSupply } from '@/utils/supply';

export default {
  name: 'BlenderStatisticSummary',
  data() {
    return {
      supply: {
        eth: 0,
        bsc: 0,
        avax: 0,
        availableShake: 0,
      },
      loading: true,
    };
  },
  computed: {
    ...mapState({
      shakeBalance: (state) => state.Shake.balance,
      totalShakeSupply: (state) => state.Shake.totalSupply,
      shakePrice: (state) => state.Blender.shakePrice,
    }),
  },
  async created() {
    this.supply = await getShakeSupply();
    this.$store.dispatch('Blender/update', {}, { root: true });
    this.loading = false;
  },
};
</script>

<template>
  <div>
  <div class="main-block blender-info">
    <div class="blender-info__item">
      <span>{{ $t('blender.your-shake') }}</span>
      <span>
        {{ (shakeBalance || 0) | amount(2) }} {{ $t('blender.shake') }}
      </span>
    </div>
    <div class="blender-info__item">
      <span class="info-title">{{ $t('blender.shake-price') }}</span>
      <span>
        {{ (shakePrice || 0) | amount(2) }} {{ $t('blender.milk') }}
      </span>
    </div>
    <div class="blender-info__item">
      <span>{{ $t('blender.available-shake-to-mint') }}</span>
      <span v-if="!loading">{{ (supply.availableShake || 0) | amount(0) }}</span>
      <span v-else> ... </span>
    </div>
    <div class="blender-info__item">
      <span>EXCLUDED FROM SUPPLY</span>
      <span>
        <a
          href="https://etherscan.io/tx/0x935fe30044c691eb32f7f57c9d909146ad771fe66d233a0f921200b2f7113b08"
          target="_blank"
        >{{ 5000 }}</a>
          </span>
    </div>
  </div>
  <div class="main-block blender-info">
    <div class="blender-info__item">
      <span> SHAKE SUPPLY (ETH) </span>
      <span v-if="!loading">{{ (supply.eth || 0) | amount(2) }}</span>
      <span v-else> ... </span>
    </div>
    <div class="blender-info__item">
      <span> SHAKE SUPPLY (BSC) </span>
      <span v-if="!loading">{{ (supply.bsc || 0) | amount(2) }}</span>
      <span v-else> ... </span>
    </div>
    <div class="blender-info__item">
      <span> SHAKE SUPPLY (Avalanche) </span>
      <span v-if="!loading">{{ (supply.avax || 0) | amount(2) }}</span>
      <span v-else> ... </span>
    </div>
  </div>
  </div>
</template>

<style lang="scss">
.blender-info {
  margin-top: 2em;
  flex-wrap: wrap;
  padding: 2em 1.8rem 0rem 1.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  @media screen and (max-width: $breakpoint-md) {
    font-size: 2rem;
  }
  @media screen and (max-width: $breakpoint-sm) {
    font-size: 1.4rem;
    flex-wrap: wrap;
    padding: 3.8em 2em;
  }

  &__item {
    font-size: 1em;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;

    font-weight: 600;
    @media screen and (max-width: $breakpoint-sm) {
      width: 47%;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: 3em;
      }
    }

    span {
      display: block;

      &:first-child {
        font-size: 1em;
        text-transform: uppercase;
        margin-bottom: .7em;
      }

      &:last-child {
        font-size: 1.7em;
      }
    }
  }
}

</style>
