import Web3 from 'web3';
import tokenAbi from '@/abi/token.json';
import avaxAddress from '@/address.avalanche';
import ethAddress from '@/address.mainnet';
import bnbAddress from '@/address.bnb';
import BigNumber from 'bignumber.js';
import Vue from 'vue';

const RPC = {
  bsc: 'https://bsc-dataseed.binance.org/',
  avax: 'https://api.avax.network/ext/bc/C/rpc',
  eth: 'https://main-light.eth.linkpool.io',
};

const shakeAddress = {
  bsc: bnbAddress.shake,
  avax: avaxAddress.shake,
  eth: ethAddress.shake,
};

export const getSupplyByNetwork = async (chain) => {
  const web3Provider = new Web3.providers.HttpProvider(RPC[chain]);
  const web3 = new Web3(web3Provider);
  const contract = new web3.eth.Contract(tokenAbi, shakeAddress[chain]);
  const totalSupply = await contract.methods.totalSupply().call();
  return Vue.$contracts.fromWei(totalSupply);
};

export const getShakeSupply = async () => {
  const networks = ['avax', 'bsc', 'eth'];
  const getAllSupply = networks.map((network) => getSupplyByNetwork(network));
  const allSupply = await Promise.all(getAllSupply);
  const namedSupply = {};
  networks.forEach((name, key) => {
    namedSupply[name] = allSupply[key];
  });

  namedSupply.eth = new BigNumber(namedSupply.eth).minus(5000);

  return {
    availableShake: new BigNumber(10000).minus(BigNumber.sum(...allSupply).toNumber()),
    ...namedSupply,
  };
};
