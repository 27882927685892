<script>
import MyChart from '@/components/Chart';
import uniGraph from '@/helpers/api/uniGraph';

export default {
  name: 'BlenderStatisticChart',
  components: { MyChart },
  data() {
    return {
      chartItems: [
        {
          name: 'Milk2/ETH',
          type: 'pair',
          address: '0xEE802b312a957e00dD7BbC08eEC8Bb93D40e981D',
        },
        {
          name: 'Shake/ETH',
          type: 'pair',
          address: '0x8f30ba1F75ec27A014591789d117a1Bb4703bFF8',
        },
        {
          name: 'Shake/Milk2',
          type: 'pair',
          address: '0x9F319E265b8189B5b5C2152cAcc791b95b3Da180',
        },
        {
          name: 'Milk2',
          type: 'token',
          address: '0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de',
        },
        {
          name: 'Shake',
          type: 'token',
          address: '0x6006fc2a849fedaba8330ce36f5133de01f96189',
        },
      ],
      chartData: null,
      currentRange: 365,
      currentChart: null,
    };
  },
  watch: {
    currentChart: {
      async handler(val) {
        if (val.type === 'pair') {
          await this.getChartPairData();
        } else {
          await this.getTokenChartData();
        }
      },
      deep: true,
    },
  },
  async created() {
    [this.currentChart] = this.chartItems;
  },
  methods: {
    async getChartPairData() {
      let data = [];
      let allFound = false;
      let skip = 0;
      try {
        /* eslint-disable no-await-in-loop */
        while (!allFound) {
          const result = await uniGraph.getPairChartData(this.currentChart.address, skip);
          skip += 1000;
          data = data.concat(result.data.data.pairDayDatas);
          if (result.data.data.pairDayDatas.length < 1000) {
            allFound = true;
          }
        }
        /* eslint-enable no-await-in-loop */
      } catch (e) {
        console.error(e);
      }
      this.chartData = data.map((elem) => ({
        date: new Date(elem.date * 1000),
        value: this.$BN(elem.reserve1).div(elem.reserve0).toNumber(),
      }));
    },
    async getTokenChartData() {
      let data = [];
      let allFound = false;
      let skip = 0;
      try {
        /* eslint-disable no-await-in-loop */
        while (!allFound) {
          const result = await uniGraph.getTokenChartData(this.currentChart.address, skip);
          skip += 1000;
          data = data.concat(result.data.data.tokenDayDatas);
          if (result.data.data.tokenDayDatas.length < 1000) {
            allFound = true;
          }
        }
        /* eslint-enable no-await-in-loop */
      } catch (e) {
        console.error(e);
      }
      this.chartData = data.map((elem) => ({
        date: new Date(elem.date * 1000),
        value: Number(elem.priceUSD),
      }));
    },
  },
};
</script>

<template>
  <div class="blender-statistic-chart main-block">
    <div class="blender-statistic-chart-controls">
      <div
        v-for="item in chartItems"
        :class="[
          'blender-statistic-chart-controls__item',
           {'blender-statistic-chart-controls__item--current': item.name === currentChart.name}
        ]"
        :key="item.name"
        @click="currentChart = item"
      >{{ item.name }}</div>
    </div>
    <MyChart
      v-if="chartData && chartData.length"
      :chartData="chartData"
      :stroke="'#a0a5d5'"
    />
  </div>
</template>

<style lang="scss">
.blender-statistic-chart {
  flex-grow: 1;
  display: flex;
  position: relative;
  @media screen and (max-width: $breakpoint-md) {
    height: 70vw;
  }
  @media screen and (max-width: $breakpoint-sm) {
    height: 110vw;
  }
  .chart {
    margin: auto 0 3rem;
    flex-grow: 1;
  }
  &-controls {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 2.7em;
    flex-wrap: wrap;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 2rem;
    }

    @media screen and (max-width: $breakpoint-sm) {
      font-size: 1.2rem;
    }

    &__item {
      width: 17%;
      padding: 2em 0;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 1em;
      text-align: center;
      cursor: pointer;
      box-shadow: $modal-shadow;

      &:hover {
        box-shadow: $main-item-shadow;
      }
      &--current {
        box-shadow: $main-item-shadow;
      }

      body.binance & {
        color: $b-dark-font-color;
        background: $b-btn-bg;
        box-shadow: $b-btn-shadow;
        &:hover {
          background: $b-btn-bg-hover;
          box-shadow: $b-btn-shadow-hover;
        }
        &--current {
          background: $b-btn-bg-active;
          box-shadow: $b-btn-bg-active;
        }
      }

      @media screen and (max-width: $breakpoint-sm) {
        min-width: calc(33% - 1rem);
        margin: 0.5rem;
        flex: 1;
      }
    }
  }
}
</style>
